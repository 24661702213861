/* tslint:disable */
/* eslint-disable */
/**
 * huntly api doc
 * huntly api doc for code generation
 *
 * The version of the OpenAPI document: 3.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiResultOfboolean
 */
export interface ApiResultOfboolean {
    /**
     * 
     * @type {number}
     * @memberof ApiResultOfboolean
     */
    'code'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiResultOfboolean
     */
    'data'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfboolean
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResultOfint
 */
export interface ApiResultOfint {
    /**
     * 
     * @type {number}
     * @memberof ApiResultOfint
     */
    'code'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiResultOfint
     */
    'data'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfint
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResultOflong
 */
export interface ApiResultOflong {
    /**
     * 
     * @type {number}
     * @memberof ApiResultOflong
     */
    'code'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiResultOflong
     */
    'data'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOflong
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiResultOfstring
 */
export interface ApiResultOfstring {
    /**
     * 
     * @type {number}
     * @memberof ApiResultOfstring
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfstring
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfstring
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ArticleContent
 */
export interface ArticleContent {
    /**
     * 
     * @type {string}
     * @memberof ArticleContent
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleContent
     */
    'pageId'?: number;
}
/**
 * 
 * @export
 * @interface CapturePage
 */
export interface CapturePage {
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'baseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'connectedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof CapturePage
     */
    'connectorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'homeUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof CapturePage
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CapturePage
     */
    'isFavorite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CapturePage
     */
    'isLiked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CapturePage
     */
    'needFindThumbUrl'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'pageJsonProperties'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'siteName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'subscribeUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'thumbUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapturePage
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface Connector
 */
export interface Connector {
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'apiToken'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Connector
     */
    'crawlFullContent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'displaySequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Connector
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'fetchIntervalSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'fetchPageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'folderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'inboxCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'lastFetchBeginAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'lastFetchEndAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Connector
     */
    'lastFetchSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'subscribeUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface ConnectorItem
 */
export interface ConnectorItem {
    /**
     * 
     * @type {string}
     * @memberof ConnectorItem
     */
    'iconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectorItem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConnectorItem
     */
    'inboxCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectorItem
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConnectorItem
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface FeedsSetting
 */
export interface FeedsSetting {
    /**
     * 
     * @type {number}
     * @memberof FeedsSetting
     */
    'connectorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FeedsSetting
     */
    'crawlFullContent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeedsSetting
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FeedsSetting
     */
    'fetchIntervalMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeedsSetting
     */
    'folderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeedsSetting
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeedsSetting
     */
    'subscribeUrl'?: string;
}
/**
 * 
 * @export
 * @interface Folder
 */
export interface Folder {
    /**
     * 
     * @type {Array<Connector>}
     * @memberof Folder
     */
    'connectors'?: Array<Connector>;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Folder
     */
    'displaySequence'?: number;
    /**
     * 
     * @type {number}
     * @memberof Folder
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FolderConnectorView
 */
export interface FolderConnectorView {
    /**
     * 
     * @type {Array<FolderConnectors>}
     * @memberof FolderConnectorView
     */
    'folderConnectors'?: Array<FolderConnectors>;
    /**
     * 
     * @type {Array<FolderConnectors>}
     * @memberof FolderConnectorView
     */
    'folderFeedConnectors'?: Array<FolderConnectors>;
}
/**
 * 
 * @export
 * @interface FolderConnectors
 */
export interface FolderConnectors {
    /**
     * 
     * @type {Array<ConnectorItem>}
     * @memberof FolderConnectors
     */
    'connectorItems'?: Array<ConnectorItem>;
    /**
     * 
     * @type {number}
     * @memberof FolderConnectors
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FolderConnectors
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface GitHubSetting
 */
export interface GitHubSetting {
    /**
     * 
     * @type {string}
     * @memberof GitHubSetting
     */
    'apiToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof GitHubSetting
     */
    'connectorId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GitHubSetting
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GitHubSetting
     */
    'fetchIntervalMinutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof GitHubSetting
     */
    'fetchPageSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof GitHubSetting
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GitHubSetting
     */
    'tokenSet'?: boolean;
}
/**
 * 
 * @export
 * @interface GlobalSetting
 */
export interface GlobalSetting {
    /**
     * 
     * @type {string}
     * @memberof GlobalSetting
     */
    'autoSaveSiteBlacklists'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalSetting
     */
    'changedOpenApiKey'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GlobalSetting
     */
    'coldDataKeepDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalSetting
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GlobalSetting
     */
    'enableProxy'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GlobalSetting
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalSetting
     */
    'openApiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalSetting
     */
    'proxyHost'?: string;
    /**
     * 
     * @type {number}
     * @memberof GlobalSetting
     */
    'proxyPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalSetting
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface InterceptTweets
 */
export interface InterceptTweets {
    /**
     * 
     * @type {string}
     * @memberof InterceptTweets
     */
    'browserScreenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterceptTweets
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterceptTweets
     */
    'jsonData'?: string;
    /**
     * 
     * @type {string}
     * @memberof InterceptTweets
     */
    'loginScreenName'?: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    'username': string;
}
/**
 * 
 * @export
 * @interface LoginUserInfo
 */
export interface LoginUserInfo {
    /**
     * 
     * @type {string}
     * @memberof LoginUserInfo
     */
    'username'?: string;
}
/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    'empty'?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ModelAndView
     */
    'model'?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ModelAndView
     */
    'modelMap'?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    'reference'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    'status'?: ModelAndViewStatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    'view'?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    'viewName'?: string;
}

export const ModelAndViewStatusEnum = {
    Accepted: 'ACCEPTED',
    AlreadyReported: 'ALREADY_REPORTED',
    BadGateway: 'BAD_GATEWAY',
    BadRequest: 'BAD_REQUEST',
    BandwidthLimitExceeded: 'BANDWIDTH_LIMIT_EXCEEDED',
    Checkpoint: 'CHECKPOINT',
    Conflict: 'CONFLICT',
    Continue: 'CONTINUE',
    Created: 'CREATED',
    DestinationLocked: 'DESTINATION_LOCKED',
    ExpectationFailed: 'EXPECTATION_FAILED',
    FailedDependency: 'FAILED_DEPENDENCY',
    Forbidden: 'FORBIDDEN',
    Found: 'FOUND',
    GatewayTimeout: 'GATEWAY_TIMEOUT',
    Gone: 'GONE',
    HttpVersionNotSupported: 'HTTP_VERSION_NOT_SUPPORTED',
    ImUsed: 'IM_USED',
    InsufficientSpaceOnResource: 'INSUFFICIENT_SPACE_ON_RESOURCE',
    InsufficientStorage: 'INSUFFICIENT_STORAGE',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    IAmATeapot: 'I_AM_A_TEAPOT',
    LengthRequired: 'LENGTH_REQUIRED',
    Locked: 'LOCKED',
    LoopDetected: 'LOOP_DETECTED',
    MethodFailure: 'METHOD_FAILURE',
    MethodNotAllowed: 'METHOD_NOT_ALLOWED',
    MovedPermanently: 'MOVED_PERMANENTLY',
    MovedTemporarily: 'MOVED_TEMPORARILY',
    MultipleChoices: 'MULTIPLE_CHOICES',
    MultiStatus: 'MULTI_STATUS',
    NetworkAuthenticationRequired: 'NETWORK_AUTHENTICATION_REQUIRED',
    NonAuthoritativeInformation: 'NON_AUTHORITATIVE_INFORMATION',
    NotAcceptable: 'NOT_ACCEPTABLE',
    NotExtended: 'NOT_EXTENDED',
    NotFound: 'NOT_FOUND',
    NotImplemented: 'NOT_IMPLEMENTED',
    NotModified: 'NOT_MODIFIED',
    NoContent: 'NO_CONTENT',
    Ok: 'OK',
    PartialContent: 'PARTIAL_CONTENT',
    PayloadTooLarge: 'PAYLOAD_TOO_LARGE',
    PaymentRequired: 'PAYMENT_REQUIRED',
    PermanentRedirect: 'PERMANENT_REDIRECT',
    PreconditionFailed: 'PRECONDITION_FAILED',
    PreconditionRequired: 'PRECONDITION_REQUIRED',
    Processing: 'PROCESSING',
    ProxyAuthenticationRequired: 'PROXY_AUTHENTICATION_REQUIRED',
    RequestedRangeNotSatisfiable: 'REQUESTED_RANGE_NOT_SATISFIABLE',
    RequestEntityTooLarge: 'REQUEST_ENTITY_TOO_LARGE',
    RequestHeaderFieldsTooLarge: 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    RequestTimeout: 'REQUEST_TIMEOUT',
    RequestUriTooLong: 'REQUEST_URI_TOO_LONG',
    ResetContent: 'RESET_CONTENT',
    SeeOther: 'SEE_OTHER',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE',
    SwitchingProtocols: 'SWITCHING_PROTOCOLS',
    TemporaryRedirect: 'TEMPORARY_REDIRECT',
    TooEarly: 'TOO_EARLY',
    TooManyRequests: 'TOO_MANY_REQUESTS',
    Unauthorized: 'UNAUTHORIZED',
    UnavailableForLegalReasons: 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UnprocessableEntity: 'UNPROCESSABLE_ENTITY',
    UnsupportedMediaType: 'UNSUPPORTED_MEDIA_TYPE',
    UpgradeRequired: 'UPGRADE_REQUIRED',
    UriTooLong: 'URI_TOO_LONG',
    UseProxy: 'USE_PROXY',
    VariantAlsoNegotiates: 'VARIANT_ALSO_NEGOTIATES'
} as const;

export type ModelAndViewStatusEnum = typeof ModelAndViewStatusEnum[keyof typeof ModelAndViewStatusEnum];

/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'archivedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'connectedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'connectorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'connectorType'?: number;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'contentText'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'contentType'?: number;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'firstReadAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'folderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'lastReadAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'librarySaveStatus'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'markRead'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'pageJsonProperties'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'pageUniqueId'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'readCostSeconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'readCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'readLater'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'readLaterAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'savedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'showOnPageList'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'sourceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'starred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'starredAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'thumbUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'urlWithoutHash'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'voteScore'?: number;
}
/**
 * 
 * @export
 * @interface PageArticleContent
 */
export interface PageArticleContent {
    /**
     * 
     * @type {number}
     * @memberof PageArticleContent
     */
    'articleContentCategory'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageArticleContent
     */
    'content'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageArticleContent
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageArticleContent
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageArticleContent
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface PageDetail
 */
export interface PageDetail {
    /**
     * 
     * @type {ConnectorItem}
     * @memberof PageDetail
     */
    'connector'?: ConnectorItem;
    /**
     * 
     * @type {Page}
     * @memberof PageDetail
     */
    'page'?: Page;
    /**
     * 
     * @type {Array<PageArticleContent>}
     * @memberof PageDetail
     */
    'pageContents'?: Array<PageArticleContent>;
    /**
     * 
     * @type {Source}
     * @memberof PageDetail
     */
    'source'?: Source;
}
/**
 * 
 * @export
 * @interface PageItem
 */
export interface PageItem {
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'connectedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'connectorId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'connectorType'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'contentType'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'folderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'language'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'librarySaveStatus'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageItem
     */
    'markRead'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'pageJsonProperties'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'pageUniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'pubDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'readCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageItem
     */
    'readLater'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'recordAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'siteName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'sourceId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageItem
     */
    'starred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'thumbUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageItem
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof PageItem
     */
    'voteScore'?: number;
}
/**
 * 
 * @export
 * @interface PageOperateResult
 */
export interface PageOperateResult {
    /**
     * 
     * @type {number}
     * @memberof PageOperateResult
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOperateResult
     */
    'librarySaveStatus'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageOperateResult
     */
    'readLater'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOperateResult
     */
    'starred'?: boolean;
}
/**
 * 
 * @export
 * @interface PageSearchResult
 */
export interface PageSearchResult {
    /**
     * 
     * @type {number}
     * @memberof PageSearchResult
     */
    'costSeconds'?: number;
    /**
     * 
     * @type {Array<PageItem>}
     * @memberof PageSearchResult
     */
    'items'?: Array<PageItem>;
    /**
     * 
     * @type {number}
     * @memberof PageSearchResult
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSearchResult
     */
    'totalHits'?: number;
}
/**
 * 
 * @export
 * @interface PreviewFeedsInfo
 */
export interface PreviewFeedsInfo {
    /**
     * 
     * @type {string}
     * @memberof PreviewFeedsInfo
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewFeedsInfo
     */
    'feedUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewFeedsInfo
     */
    'siteFaviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewFeedsInfo
     */
    'siteLink'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewFeedsInfo
     */
    'subscribed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PreviewFeedsInfo
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface SearchHistory
 */
export interface SearchHistory {
    /**
     * 
     * @type {number}
     * @memberof SearchHistory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchHistory
     */
    'options'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchHistory
     */
    'query'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchHistory
     */
    'searchAt'?: string;
}
/**
 * 
 * @export
 * @interface SearchQuery
 */
export interface SearchQuery {
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    'q'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchQuery
     */
    'queryOptions'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchQuery
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'faviconUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'homeUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof Source
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'siteName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'subscribeUrl'?: string;
}
/**
 * 
 * @export
 * @interface TweetId
 */
export interface TweetId {
    /**
     * 
     * @type {string}
     * @memberof TweetId
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface TweetTrack
 */
export interface TweetTrack {
    /**
     * 
     * @type {number}
     * @memberof TweetTrack
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TweetTrack
     */
    'readAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TweetTrack
     */
    'setReadAt'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TweetTrack
     */
    'tweetId'?: string;
}
/**
 * 
 * @export
 * @interface TwitterUserSetting
 */
export interface TwitterUserSetting {
    /**
     * 
     * @type {number}
     * @memberof TwitterUserSetting
     */
    'bookmarkToLibraryType'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwitterUserSetting
     */
    'createdAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof TwitterUserSetting
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TwitterUserSetting
     */
    'likeToLibraryType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TwitterUserSetting
     */
    'myself'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TwitterUserSetting
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TwitterUserSetting
     */
    'screenName'?: string;
    /**
     * 
     * @type {number}
     * @memberof TwitterUserSetting
     */
    'tweetToLibraryType'?: number;
    /**
     * 
     * @type {string}
     * @memberof TwitterUserSetting
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    'contentType'?: string;
}

/**
 * AuthControllerApi - axios parameter creator
 * @export
 */
export const AuthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary isUserSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserSetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/isUserSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginUserInfo
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserInfoUsingGET: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/loginUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary signin
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinUsingPOST: async (loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/signin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary signup
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST: async (loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary singOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singOutUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/signOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary isUserSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUserSetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfboolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUserSetUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary loginUserInfo
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUserInfoUsingGET(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUserInfoUsingGET(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary signin
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signinUsingPOST(loginRequest?: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfstring>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signinUsingPOST(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary signup
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signupUsingPOST(loginRequest?: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfstring>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signupUsingPOST(loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary singOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singOutUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfstring>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singOutUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary isUserSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUserSetUsingGET(options?: any): AxiosPromise<ApiResultOfboolean> {
            return localVarFp.isUserSetUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loginUserInfo
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUserInfoUsingGET(name?: string, options?: any): AxiosPromise<LoginUserInfo> {
            return localVarFp.loginUserInfoUsingGET(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary signin
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signinUsingPOST(loginRequest?: LoginRequest, options?: any): AxiosPromise<ApiResultOfstring> {
            return localVarFp.signinUsingPOST(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary signup
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signupUsingPOST(loginRequest?: LoginRequest, options?: any): AxiosPromise<ApiResultOfstring> {
            return localVarFp.signupUsingPOST(loginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary singOut
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singOutUsingPOST(options?: any): AxiosPromise<ApiResultOfstring> {
            return localVarFp.singOutUsingPOST(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary isUserSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public isUserSetUsingGET(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).isUserSetUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loginUserInfo
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginUserInfoUsingGET(name?: string, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).loginUserInfoUsingGET(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary signin
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public signinUsingPOST(loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).signinUsingPOST(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary signup
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public signupUsingPOST(loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).signupUsingPOST(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary singOut
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public singOutUsingPOST(options?: AxiosRequestConfig) {
        return AuthControllerApiFp(this.configuration).singOutUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BasicErrorControllerApi - axios parameter creator
 * @export
 */
export const BasicErrorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingTRACE: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'TRACE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BasicErrorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingDELETE(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingDELETE(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingHEAD(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingHEAD(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingOPTIONS(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingOPTIONS(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPATCH(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingPATCH(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPOST(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingPOST(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPUT(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingPUT(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingTRACE(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorHtmlUsingTRACE(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BasicErrorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingDELETE(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingHEAD(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingOPTIONS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingPATCH(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingPUT(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingTRACE(options?: any): AxiosPromise<ModelAndView> {
            return localVarFp.errorHtmlUsingTRACE(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingDELETE(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingDELETE(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingGET(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingHEAD(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingHEAD(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingOPTIONS(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingOPTIONS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPATCH(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPATCH(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPOST(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPUT(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPUT(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingTRACE(options?: AxiosRequestConfig) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingTRACE(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectorControllerApi - axios parameter creator
 * @export
 */
export const ConnectorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getConnectorById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getConnectorByIdUsingGET', 'id', id)
            const localVarPath = `/api/connector/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFolderConnectorView
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderConnectorViewUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/connector/folder-connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectorControllerApi - functional programming interface
 * @export
 */
export const ConnectorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getConnectorById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getFolderConnectorView
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderConnectorViewUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FolderConnectorView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderConnectorViewUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectorControllerApi - factory interface
 * @export
 */
export const ConnectorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getConnectorById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorByIdUsingGET(id: number, options?: any): AxiosPromise<Connector> {
            return localVarFp.getConnectorByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFolderConnectorView
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderConnectorViewUsingGET(options?: any): AxiosPromise<FolderConnectorView> {
            return localVarFp.getFolderConnectorViewUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectorControllerApi - object-oriented interface
 * @export
 * @class ConnectorControllerApi
 * @extends {BaseAPI}
 */
export class ConnectorControllerApi extends BaseAPI {
    /**
     * 
     * @summary getConnectorById
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorControllerApi
     */
    public getConnectorByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return ConnectorControllerApiFp(this.configuration).getConnectorByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFolderConnectorView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorControllerApi
     */
    public getFolderConnectorViewUsingGET(options?: AxiosRequestConfig) {
        return ConnectorControllerApiFp(this.configuration).getFolderConnectorViewUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderControllerApi - axios parameter creator
 * @export
 */
export const FolderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getFolderById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFolderByIdUsingGET', 'id', id)
            const localVarPath = `/api/folder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderControllerApi - functional programming interface
 * @export
 */
export const FolderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getFolderById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderControllerApi - factory interface
 * @export
 */
export const FolderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getFolderById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderByIdUsingGET(id: number, options?: any): AxiosPromise<Folder> {
            return localVarFp.getFolderByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderControllerApi - object-oriented interface
 * @export
 * @class FolderControllerApi
 * @extends {BaseAPI}
 */
export class FolderControllerApi extends BaseAPI {
    /**
     * 
     * @summary getFolderById
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderControllerApi
     */
    public getFolderByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return FolderControllerApiFp(this.configuration).getFolderByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthControllerApi - axios parameter creator
 * @export
 */
export const HealthControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthControllerApi - functional programming interface
 * @export
 */
export const HealthControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthControllerApi - factory interface
 * @export
 */
export const HealthControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary health
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.healthUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthControllerApi - object-oriented interface
 * @export
 * @class HealthControllerApi
 * @extends {BaseAPI}
 */
export class HealthControllerApi extends BaseAPI {
    /**
     * 
     * @summary health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthControllerApi
     */
    public healthUsingGET(options?: AxiosRequestConfig) {
        return HealthControllerApiFp(this.configuration).healthUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageControllerApi - axios parameter creator
 * @export
 */
export const PageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary archiveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveToLibraryUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveToLibraryUsingPOST', 'id', id)
            const localVarPath = `/api/page/archive/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageUsingDELETE: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePageUsingDELETE', 'id', id)
            const localVarPath = `/api/page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchFullContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullContentByIdUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchFullContentByIdUsingPOST', 'id', id)
            const localVarPath = `/api/page/fullContent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPageDetailById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageDetailByIdUsingGET: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPageDetailByIdUsingGET', 'id', id)
            const localVarPath = `/api/page/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPageOperateResult
         * @param {number} [id] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageOperateResultUsingGET: async (id?: number, url?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/pageOperateResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary listPageItems
         * @param {boolean} [asc] 
         * @param {number} [connectorId] 
         * @param {number} [connectorType] 
         * @param {number} [contentFilterType] 
         * @param {'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET'} [contentType] 
         * @param {number} [count] 
         * @param {string} [endDate] 
         * @param {string} [firstRecordAt] 
         * @param {number} [firstVoteScore] 
         * @param {number} [folderId] 
         * @param {string} [lastRecordAt] 
         * @param {number} [lastVoteScore] 
         * @param {boolean} [markRead] 
         * @param {boolean} [readLater] 
         * @param {'ARCHIVED' | 'NOT_SAVED' | 'SAVED'} [saveStatus] 
         * @param {'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE'} [sort] 
         * @param {number} [sourceId] 
         * @param {boolean} [starred] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageItemsUsingGET: async (asc?: boolean, connectorId?: number, connectorType?: number, contentFilterType?: number, contentType?: 'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET', count?: number, endDate?: string, firstRecordAt?: string, firstVoteScore?: number, folderId?: number, lastRecordAt?: string, lastVoteScore?: number, markRead?: boolean, readLater?: boolean, saveStatus?: 'ARCHIVED' | 'NOT_SAVED' | 'SAVED', sort?: 'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE', sourceId?: number, starred?: boolean, startDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (asc !== undefined) {
                localVarQueryParameter['asc'] = asc;
            }

            if (connectorId !== undefined) {
                localVarQueryParameter['connectorId'] = connectorId;
            }

            if (connectorType !== undefined) {
                localVarQueryParameter['connectorType'] = connectorType;
            }

            if (contentFilterType !== undefined) {
                localVarQueryParameter['contentFilterType'] = contentFilterType;
            }

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (firstRecordAt !== undefined) {
                localVarQueryParameter['firstRecordAt'] = (firstRecordAt as any instanceof Date) ?
                    (firstRecordAt as any).toISOString() :
                    firstRecordAt;
            }

            if (firstVoteScore !== undefined) {
                localVarQueryParameter['firstVoteScore'] = firstVoteScore;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (lastRecordAt !== undefined) {
                localVarQueryParameter['lastRecordAt'] = (lastRecordAt as any instanceof Date) ?
                    (lastRecordAt as any).toISOString() :
                    lastRecordAt;
            }

            if (lastVoteScore !== undefined) {
                localVarQueryParameter['lastVoteScore'] = lastVoteScore;
            }

            if (markRead !== undefined) {
                localVarQueryParameter['markRead'] = markRead;
            }

            if (readLater !== undefined) {
                localVarQueryParameter['readLater'] = readLater;
            }

            if (saveStatus !== undefined) {
                localVarQueryParameter['saveStatus'] = saveStatus;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (starred !== undefined) {
                localVarQueryParameter['starred'] = starred;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markReadByConnectorId
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByConnectorIdUsingPOST: async (connectorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('markReadByConnectorIdUsingPOST', 'connectorId', connectorId)
            const localVarPath = `/api/page/markReadByConnectorId/{connectorId}`
                .replace(`{${"connectorId"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markReadByConnectorType
         * @param {number} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByConnectorTypeUsingPOST: async (type: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('markReadByConnectorTypeUsingPOST', 'type', type)
            const localVarPath = `/api/page/markReadByConnectorType/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markReadByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByFolderIdUsingPOST: async (folderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('markReadByFolderIdUsingPOST', 'folderId', folderId)
            const localVarPath = `/api/page/markReadByFolderId/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markReadByPageIds
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByPageIdsUsingPOST: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/markReadByPageIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markReadPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/markRead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary readLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLaterPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readLaterPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/readLater/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary recordReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordReadPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('recordReadPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/recordReadPage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeFromLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromLibraryUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeFromLibraryUsingPOST', 'id', id)
            const localVarPath = `/api/page/removeFromLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePage
         * @param {CapturePage} [capturePage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageUsingPOST: async (capturePage?: CapturePage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/page/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(capturePage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveToLibraryUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveToLibraryUsingPOST', 'id', id)
            const localVarPath = `/api/page/saveToLibrary/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary starPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('starPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/star/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary switchRawContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchRawContentByIdUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('switchRawContentByIdUsingPOST', 'id', id)
            const localVarPath = `/api/page/rawContent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unMarkReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unMarkReadPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unMarkReadPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/unMarkRead/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unReadLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unReadLaterPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unReadLaterPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/unReadLater/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unStarPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unStarPageUsingPOST: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unStarPageUsingPOST', 'id', id)
            const localVarPath = `/api/page/unStar/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageControllerApi - functional programming interface
 * @export
 */
export const PageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary archiveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveToLibraryUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveToLibraryUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deletePage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePageUsingDELETE(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePageUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary fetchFullContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchFullContentByIdUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchFullContentByIdUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getPageDetailById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageDetailByIdUsingGET(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageDetailByIdUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getPageOperateResult
         * @param {number} [id] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageOperateResultUsingGET(id?: number, url?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageOperateResultUsingGET(id, url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary listPageItems
         * @param {boolean} [asc] 
         * @param {number} [connectorId] 
         * @param {number} [connectorType] 
         * @param {number} [contentFilterType] 
         * @param {'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET'} [contentType] 
         * @param {number} [count] 
         * @param {string} [endDate] 
         * @param {string} [firstRecordAt] 
         * @param {number} [firstVoteScore] 
         * @param {number} [folderId] 
         * @param {string} [lastRecordAt] 
         * @param {number} [lastVoteScore] 
         * @param {boolean} [markRead] 
         * @param {boolean} [readLater] 
         * @param {'ARCHIVED' | 'NOT_SAVED' | 'SAVED'} [saveStatus] 
         * @param {'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE'} [sort] 
         * @param {number} [sourceId] 
         * @param {boolean} [starred] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPageItemsUsingGET(asc?: boolean, connectorId?: number, connectorType?: number, contentFilterType?: number, contentType?: 'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET', count?: number, endDate?: string, firstRecordAt?: string, firstVoteScore?: number, folderId?: number, lastRecordAt?: string, lastVoteScore?: number, markRead?: boolean, readLater?: boolean, saveStatus?: 'ARCHIVED' | 'NOT_SAVED' | 'SAVED', sort?: 'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE', sourceId?: number, starred?: boolean, startDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PageItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPageItemsUsingGET(asc, connectorId, connectorType, contentFilterType, contentType, count, endDate, firstRecordAt, firstVoteScore, folderId, lastRecordAt, lastVoteScore, markRead, readLater, saveStatus, sort, sourceId, starred, startDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markReadByConnectorId
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markReadByConnectorIdUsingPOST(connectorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markReadByConnectorIdUsingPOST(connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markReadByConnectorType
         * @param {number} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markReadByConnectorTypeUsingPOST(type: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markReadByConnectorTypeUsingPOST(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markReadByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markReadByFolderIdUsingPOST(folderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markReadByFolderIdUsingPOST(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markReadByPageIds
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markReadByPageIdsUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markReadByPageIdsUsingPOST(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary markReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markReadPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markReadPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary readLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readLaterPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readLaterPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary recordReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordReadPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordReadPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary removeFromLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromLibraryUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromLibraryUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary savePage
         * @param {CapturePage} [capturePage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePageUsingPOST(capturePage?: CapturePage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOflong>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePageUsingPOST(capturePage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveToLibraryUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveToLibraryUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary starPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async starPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.starPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary switchRawContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async switchRawContentByIdUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.switchRawContentByIdUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unMarkReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unMarkReadPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unMarkReadPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unReadLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unReadLaterPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unReadLaterPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary unStarPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unStarPageUsingPOST(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOperateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unStarPageUsingPOST(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageControllerApi - factory interface
 * @export
 */
export const PageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary archiveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveToLibraryUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.archiveToLibraryUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deletePage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePageUsingDELETE(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePageUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchFullContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchFullContentByIdUsingPOST(id: number, options?: any): AxiosPromise<ArticleContent> {
            return localVarFp.fetchFullContentByIdUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPageDetailById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageDetailByIdUsingGET(id: number, options?: any): AxiosPromise<PageDetail> {
            return localVarFp.getPageDetailByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPageOperateResult
         * @param {number} [id] 
         * @param {string} [url] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageOperateResultUsingGET(id?: number, url?: string, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.getPageOperateResultUsingGET(id, url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary listPageItems
         * @param {boolean} [asc] 
         * @param {number} [connectorId] 
         * @param {number} [connectorType] 
         * @param {number} [contentFilterType] 
         * @param {'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET'} [contentType] 
         * @param {number} [count] 
         * @param {string} [endDate] 
         * @param {string} [firstRecordAt] 
         * @param {number} [firstVoteScore] 
         * @param {number} [folderId] 
         * @param {string} [lastRecordAt] 
         * @param {number} [lastVoteScore] 
         * @param {boolean} [markRead] 
         * @param {boolean} [readLater] 
         * @param {'ARCHIVED' | 'NOT_SAVED' | 'SAVED'} [saveStatus] 
         * @param {'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE'} [sort] 
         * @param {number} [sourceId] 
         * @param {boolean} [starred] 
         * @param {string} [startDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPageItemsUsingGET(asc?: boolean, connectorId?: number, connectorType?: number, contentFilterType?: number, contentType?: 'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET', count?: number, endDate?: string, firstRecordAt?: string, firstVoteScore?: number, folderId?: number, lastRecordAt?: string, lastVoteScore?: number, markRead?: boolean, readLater?: boolean, saveStatus?: 'ARCHIVED' | 'NOT_SAVED' | 'SAVED', sort?: 'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE', sourceId?: number, starred?: boolean, startDate?: string, options?: any): AxiosPromise<Array<PageItem>> {
            return localVarFp.listPageItemsUsingGET(asc, connectorId, connectorType, contentFilterType, contentType, count, endDate, firstRecordAt, firstVoteScore, folderId, lastRecordAt, lastVoteScore, markRead, readLater, saveStatus, sort, sourceId, starred, startDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markReadByConnectorId
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByConnectorIdUsingPOST(connectorId: number, options?: any): AxiosPromise<ApiResultOfint> {
            return localVarFp.markReadByConnectorIdUsingPOST(connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markReadByConnectorType
         * @param {number} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByConnectorTypeUsingPOST(type: number, options?: any): AxiosPromise<ApiResultOfint> {
            return localVarFp.markReadByConnectorTypeUsingPOST(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markReadByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByFolderIdUsingPOST(folderId: number, options?: any): AxiosPromise<ApiResultOfint> {
            return localVarFp.markReadByFolderIdUsingPOST(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markReadByPageIds
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadByPageIdsUsingPOST(requestBody?: Array<number>, options?: any): AxiosPromise<ApiResultOfint> {
            return localVarFp.markReadByPageIdsUsingPOST(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary markReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markReadPageUsingPOST(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.markReadPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary readLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readLaterPageUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.readLaterPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary recordReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordReadPageUsingPOST(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.recordReadPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary removeFromLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromLibraryUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.removeFromLibraryUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePage
         * @param {CapturePage} [capturePage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePageUsingPOST(capturePage?: CapturePage, options?: any): AxiosPromise<ApiResultOflong> {
            return localVarFp.savePageUsingPOST(capturePage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveToLibrary
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveToLibraryUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.saveToLibraryUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary starPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        starPageUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.starPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary switchRawContentById
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        switchRawContentByIdUsingPOST(id: number, options?: any): AxiosPromise<ArticleContent> {
            return localVarFp.switchRawContentByIdUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unMarkReadPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unMarkReadPageUsingPOST(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.unMarkReadPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unReadLaterPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unReadLaterPageUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.unReadLaterPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary unStarPage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unStarPageUsingPOST(id: number, options?: any): AxiosPromise<PageOperateResult> {
            return localVarFp.unStarPageUsingPOST(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageControllerApi - object-oriented interface
 * @export
 * @class PageControllerApi
 * @extends {BaseAPI}
 */
export class PageControllerApi extends BaseAPI {
    /**
     * 
     * @summary archiveToLibrary
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public archiveToLibraryUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).archiveToLibraryUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deletePage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public deletePageUsingDELETE(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).deletePageUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchFullContentById
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public fetchFullContentByIdUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).fetchFullContentByIdUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPageDetailById
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public getPageDetailByIdUsingGET(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).getPageDetailByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPageOperateResult
     * @param {number} [id] 
     * @param {string} [url] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public getPageOperateResultUsingGET(id?: number, url?: string, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).getPageOperateResultUsingGET(id, url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary listPageItems
     * @param {boolean} [asc] 
     * @param {number} [connectorId] 
     * @param {number} [connectorType] 
     * @param {number} [contentFilterType] 
     * @param {'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET'} [contentType] 
     * @param {number} [count] 
     * @param {string} [endDate] 
     * @param {string} [firstRecordAt] 
     * @param {number} [firstVoteScore] 
     * @param {number} [folderId] 
     * @param {string} [lastRecordAt] 
     * @param {number} [lastVoteScore] 
     * @param {boolean} [markRead] 
     * @param {boolean} [readLater] 
     * @param {'ARCHIVED' | 'NOT_SAVED' | 'SAVED'} [saveStatus] 
     * @param {'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE'} [sort] 
     * @param {number} [sourceId] 
     * @param {boolean} [starred] 
     * @param {string} [startDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public listPageItemsUsingGET(asc?: boolean, connectorId?: number, connectorType?: number, contentFilterType?: number, contentType?: 'BROWSER_HISTORY' | 'MARKDOWN' | 'QUOTED_TWEET' | 'TWEET', count?: number, endDate?: string, firstRecordAt?: string, firstVoteScore?: number, folderId?: number, lastRecordAt?: string, lastVoteScore?: number, markRead?: boolean, readLater?: boolean, saveStatus?: 'ARCHIVED' | 'NOT_SAVED' | 'SAVED', sort?: 'ARCHIVED_AT' | 'CONNECTED_AT' | 'CREATED_AT' | 'LAST_READ_AT' | 'READ_LATER_AT' | 'SAVED_AT' | 'STARRED_AT' | 'VOTE_SCORE', sourceId?: number, starred?: boolean, startDate?: string, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).listPageItemsUsingGET(asc, connectorId, connectorType, contentFilterType, contentType, count, endDate, firstRecordAt, firstVoteScore, folderId, lastRecordAt, lastVoteScore, markRead, readLater, saveStatus, sort, sourceId, starred, startDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markReadByConnectorId
     * @param {number} connectorId connectorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public markReadByConnectorIdUsingPOST(connectorId: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).markReadByConnectorIdUsingPOST(connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markReadByConnectorType
     * @param {number} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public markReadByConnectorTypeUsingPOST(type: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).markReadByConnectorTypeUsingPOST(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markReadByFolderId
     * @param {number} folderId folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public markReadByFolderIdUsingPOST(folderId: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).markReadByFolderIdUsingPOST(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markReadByPageIds
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public markReadByPageIdsUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).markReadByPageIdsUsingPOST(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary markReadPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public markReadPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).markReadPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary readLaterPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public readLaterPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).readLaterPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary recordReadPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public recordReadPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).recordReadPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary removeFromLibrary
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public removeFromLibraryUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).removeFromLibraryUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePage
     * @param {CapturePage} [capturePage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public savePageUsingPOST(capturePage?: CapturePage, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).savePageUsingPOST(capturePage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveToLibrary
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public saveToLibraryUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).saveToLibraryUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary starPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public starPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).starPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary switchRawContentById
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public switchRawContentByIdUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).switchRawContentByIdUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unMarkReadPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public unMarkReadPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).unMarkReadPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unReadLaterPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public unReadLaterPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).unReadLaterPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary unStarPage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageControllerApi
     */
    public unStarPageUsingPOST(id: number, options?: AxiosRequestConfig) {
        return PageControllerApiFp(this.configuration).unStarPageUsingPOST(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchControllerApi - axios parameter creator
 * @export
 */
export const SearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getRecentSearches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentSearchesUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchPages
         * @param {SearchQuery} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPagesUsingPOST: async (searchQuery?: SearchQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getRecentSearches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentSearchesUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentSearchesUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary searchPages
         * @param {SearchQuery} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPagesUsingPOST(searchQuery?: SearchQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPagesUsingPOST(searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getRecentSearches
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentSearchesUsingGET(options?: any): AxiosPromise<Array<SearchHistory>> {
            return localVarFp.getRecentSearchesUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchPages
         * @param {SearchQuery} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPagesUsingPOST(searchQuery?: SearchQuery, options?: any): AxiosPromise<PageSearchResult> {
            return localVarFp.searchPagesUsingPOST(searchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
    /**
     * 
     * @summary getRecentSearches
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public getRecentSearchesUsingGET(options?: AxiosRequestConfig) {
        return SearchControllerApiFp(this.configuration).getRecentSearchesUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchPages
     * @param {SearchQuery} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public searchPagesUsingPOST(searchQuery?: SearchQuery, options?: AxiosRequestConfig) {
        return SearchControllerApiFp(this.configuration).searchPagesUsingPOST(searchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingControllerApi - axios parameter creator
 * @export
 */
export const SettingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteFeed
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeedUsingPOST: async (connectorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('deleteFeedUsingPOST', 'connectorId', connectorId)
            const localVarPath = `/api/setting/feeds/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (connectorId !== undefined) {
                localVarQueryParameter['connectorId'] = connectorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteFolder
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderUsingPOST: async (folderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('deleteFolderUsingPOST', 'folderId', folderId)
            const localVarPath = `/api/setting/folder/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary followFeed
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followFeedUsingPOST: async (subscribeUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeUrl' is not null or undefined
            assertParamExists('followFeedUsingPOST', 'subscribeUrl', subscribeUrl)
            const localVarPath = `/api/setting/feeds/follow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subscribeUrl !== undefined) {
                localVarQueryParameter['subscribeUrl'] = subscribeUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBlacklist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklistUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/general/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFeedsSetting
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedsSettingUsingGET: async (connectorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getFeedsSettingUsingGET', 'connectorId', connectorId)
            const localVarPath = `/api/setting/feeds/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (connectorId !== undefined) {
                localVarQueryParameter['connectorId'] = connectorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGitHubSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGitHubSettingUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/github/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGlobalSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettingUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/general/globalSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSortedConnectorsByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSortedConnectorsByFolderIdUsingGET: async (folderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('getSortedConnectorsByFolderIdUsingGET', 'folderId', folderId)
            const localVarPath = `/api/setting/folder/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSortedFolders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSortedFoldersUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/folder/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTwitterUserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwitterUserSettingsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/twitter/UserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importOpml
         * @param {File} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOpmlUsingPOST: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('importOpmlUsingPOST', 'file', file)
            const localVarPath = `/api/setting/feeds/import-opml`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary isGithubPersonalTokenSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGithubPersonalTokenSetUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/github/is-token-set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary previewFeeds
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewFeedsUsingGET: async (subscribeUrl: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscribeUrl' is not null or undefined
            assertParamExists('previewFeedsUsingGET', 'subscribeUrl', subscribeUrl)
            const localVarPath = `/api/setting/feeds/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subscribeUrl !== undefined) {
                localVarQueryParameter['subscribeUrl'] = subscribeUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resortConnectors
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resortConnectorsUsingPOST: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/feeds/resort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resortFolders
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resortFoldersUsingPOST: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/folder/resort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveFolder
         * @param {Folder} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFolderUsingPOST: async (folder?: Folder, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/folder/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(folder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveGitHubSetting
         * @param {GitHubSetting} [gitHubSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGitHubSettingUsingPOST: async (gitHubSetting?: GitHubSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/github/saveSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gitHubSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveGithubPersonalToken
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGithubPersonalTokenUsingPOST: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('saveGithubPersonalTokenUsingPOST', 'token', token)
            const localVarPath = `/api/setting/github/save-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveGlobalSetting
         * @param {GlobalSetting} [globalSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGlobalSettingUsingPOST: async (globalSetting?: GlobalSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/general/saveGlobalSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveTwitterUserSettings
         * @param {Array<TwitterUserSetting>} [twitterUserSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTwitterUserSettingsUsingPOST: async (twitterUserSetting?: Array<TwitterUserSetting>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/twitter/saveUserSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twitterUserSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateFeedsSetting
         * @param {FeedsSetting} [feedsSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedsSettingUsingPOST: async (feedsSetting?: FeedsSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/feeds/updateSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feedsSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateLoginUser
         * @param {string} [name] 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoginUserUsingPOST: async (name?: string, loginRequest?: LoginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/setting/user/updateLoginUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingControllerApi - functional programming interface
 * @export
 */
export const SettingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary deleteFeed
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeedUsingPOST(connectorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeedUsingPOST(connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteFolder
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFolderUsingPOST(folderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFolderUsingPOST(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary followFeed
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async followFeedUsingPOST(subscribeUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.followFeedUsingPOST(subscribeUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getBlacklist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlacklistUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlacklistUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getFeedsSetting
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeedsSettingUsingGET(connectorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeedsSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeedsSettingUsingGET(connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getGitHubSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGitHubSettingUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitHubSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGitHubSettingUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getGlobalSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalSettingUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalSettingUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSortedConnectorsByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSortedConnectorsByFolderIdUsingGET(folderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Connector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSortedConnectorsByFolderIdUsingGET(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getSortedFolders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSortedFoldersUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Folder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSortedFoldersUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTwitterUserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTwitterUserSettingsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TwitterUserSetting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTwitterUserSettingsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary importOpml
         * @param {File} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importOpmlUsingPOST(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importOpmlUsingPOST(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary isGithubPersonalTokenSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isGithubPersonalTokenSetUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfboolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isGithubPersonalTokenSetUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary previewFeeds
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewFeedsUsingGET(subscribeUrl: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewFeedsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewFeedsUsingGET(subscribeUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary resortConnectors
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resortConnectorsUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resortConnectorsUsingPOST(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary resortFolders
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resortFoldersUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resortFoldersUsingPOST(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveFolder
         * @param {Folder} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFolderUsingPOST(folder?: Folder, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFolderUsingPOST(folder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveGitHubSetting
         * @param {GitHubSetting} [gitHubSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGitHubSettingUsingPOST(gitHubSetting?: GitHubSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGitHubSettingUsingPOST(gitHubSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveGithubPersonalToken
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGithubPersonalTokenUsingPOST(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGithubPersonalTokenUsingPOST(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveGlobalSetting
         * @param {GlobalSetting} [globalSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGlobalSettingUsingPOST(globalSetting?: GlobalSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GlobalSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGlobalSettingUsingPOST(globalSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saveTwitterUserSettings
         * @param {Array<TwitterUserSetting>} [twitterUserSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTwitterUserSettingsUsingPOST(twitterUserSetting?: Array<TwitterUserSetting>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTwitterUserSettingsUsingPOST(twitterUserSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateFeedsSetting
         * @param {FeedsSetting} [feedsSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeedsSettingUsingPOST(feedsSetting?: FeedsSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeedsSettingUsingPOST(feedsSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateLoginUser
         * @param {string} [name] 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLoginUserUsingPOST(name?: string, loginRequest?: LoginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLoginUserUsingPOST(name, loginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingControllerApi - factory interface
 * @export
 */
export const SettingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary deleteFeed
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeedUsingPOST(connectorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFeedUsingPOST(connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteFolder
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderUsingPOST(folderId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFolderUsingPOST(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary followFeed
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        followFeedUsingPOST(subscribeUrl: string, options?: any): AxiosPromise<Connector> {
            return localVarFp.followFeedUsingPOST(subscribeUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getBlacklist
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlacklistUsingGET(options?: any): AxiosPromise<string> {
            return localVarFp.getBlacklistUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getFeedsSetting
         * @param {number} connectorId connectorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeedsSettingUsingGET(connectorId: number, options?: any): AxiosPromise<FeedsSetting> {
            return localVarFp.getFeedsSettingUsingGET(connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getGitHubSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGitHubSettingUsingGET(options?: any): AxiosPromise<GitHubSetting> {
            return localVarFp.getGitHubSettingUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getGlobalSetting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalSettingUsingGET(options?: any): AxiosPromise<GlobalSetting> {
            return localVarFp.getGlobalSettingUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSortedConnectorsByFolderId
         * @param {number} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSortedConnectorsByFolderIdUsingGET(folderId: number, options?: any): AxiosPromise<Array<Connector>> {
            return localVarFp.getSortedConnectorsByFolderIdUsingGET(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getSortedFolders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSortedFoldersUsingGET(options?: any): AxiosPromise<Array<Folder>> {
            return localVarFp.getSortedFoldersUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTwitterUserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTwitterUserSettingsUsingGET(options?: any): AxiosPromise<Array<TwitterUserSetting>> {
            return localVarFp.getTwitterUserSettingsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary importOpml
         * @param {File} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importOpmlUsingPOST(file: File, options?: any): AxiosPromise<void> {
            return localVarFp.importOpmlUsingPOST(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary isGithubPersonalTokenSet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isGithubPersonalTokenSetUsingGET(options?: any): AxiosPromise<ApiResultOfboolean> {
            return localVarFp.isGithubPersonalTokenSetUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary previewFeeds
         * @param {string} subscribeUrl subscribeUrl
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewFeedsUsingGET(subscribeUrl: string, options?: any): AxiosPromise<PreviewFeedsInfo> {
            return localVarFp.previewFeedsUsingGET(subscribeUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resortConnectors
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resortConnectorsUsingPOST(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.resortConnectorsUsingPOST(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary resortFolders
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resortFoldersUsingPOST(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.resortFoldersUsingPOST(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveFolder
         * @param {Folder} [folder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFolderUsingPOST(folder?: Folder, options?: any): AxiosPromise<Folder> {
            return localVarFp.saveFolderUsingPOST(folder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveGitHubSetting
         * @param {GitHubSetting} [gitHubSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGitHubSettingUsingPOST(gitHubSetting?: GitHubSetting, options?: any): AxiosPromise<Connector> {
            return localVarFp.saveGitHubSettingUsingPOST(gitHubSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveGithubPersonalToken
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGithubPersonalTokenUsingPOST(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.saveGithubPersonalTokenUsingPOST(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveGlobalSetting
         * @param {GlobalSetting} [globalSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGlobalSettingUsingPOST(globalSetting?: GlobalSetting, options?: any): AxiosPromise<GlobalSetting> {
            return localVarFp.saveGlobalSettingUsingPOST(globalSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveTwitterUserSettings
         * @param {Array<TwitterUserSetting>} [twitterUserSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTwitterUserSettingsUsingPOST(twitterUserSetting?: Array<TwitterUserSetting>, options?: any): AxiosPromise<void> {
            return localVarFp.saveTwitterUserSettingsUsingPOST(twitterUserSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateFeedsSetting
         * @param {FeedsSetting} [feedsSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeedsSettingUsingPOST(feedsSetting?: FeedsSetting, options?: any): AxiosPromise<Connector> {
            return localVarFp.updateFeedsSettingUsingPOST(feedsSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateLoginUser
         * @param {string} [name] 
         * @param {LoginRequest} [loginRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLoginUserUsingPOST(name?: string, loginRequest?: LoginRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateLoginUserUsingPOST(name, loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingControllerApi - object-oriented interface
 * @export
 * @class SettingControllerApi
 * @extends {BaseAPI}
 */
export class SettingControllerApi extends BaseAPI {
    /**
     * 
     * @summary deleteFeed
     * @param {number} connectorId connectorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public deleteFeedUsingPOST(connectorId: number, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).deleteFeedUsingPOST(connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteFolder
     * @param {number} folderId folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public deleteFolderUsingPOST(folderId: number, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).deleteFolderUsingPOST(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary followFeed
     * @param {string} subscribeUrl subscribeUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public followFeedUsingPOST(subscribeUrl: string, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).followFeedUsingPOST(subscribeUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getBlacklist
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getBlacklistUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getBlacklistUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getFeedsSetting
     * @param {number} connectorId connectorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getFeedsSettingUsingGET(connectorId: number, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getFeedsSettingUsingGET(connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getGitHubSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getGitHubSettingUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getGitHubSettingUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getGlobalSetting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getGlobalSettingUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getGlobalSettingUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSortedConnectorsByFolderId
     * @param {number} folderId folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getSortedConnectorsByFolderIdUsingGET(folderId: number, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getSortedConnectorsByFolderIdUsingGET(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getSortedFolders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getSortedFoldersUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getSortedFoldersUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTwitterUserSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public getTwitterUserSettingsUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).getTwitterUserSettingsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary importOpml
     * @param {File} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public importOpmlUsingPOST(file: File, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).importOpmlUsingPOST(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary isGithubPersonalTokenSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public isGithubPersonalTokenSetUsingGET(options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).isGithubPersonalTokenSetUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary previewFeeds
     * @param {string} subscribeUrl subscribeUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public previewFeedsUsingGET(subscribeUrl: string, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).previewFeedsUsingGET(subscribeUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resortConnectors
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public resortConnectorsUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).resortConnectorsUsingPOST(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary resortFolders
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public resortFoldersUsingPOST(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).resortFoldersUsingPOST(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveFolder
     * @param {Folder} [folder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public saveFolderUsingPOST(folder?: Folder, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).saveFolderUsingPOST(folder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveGitHubSetting
     * @param {GitHubSetting} [gitHubSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public saveGitHubSettingUsingPOST(gitHubSetting?: GitHubSetting, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).saveGitHubSettingUsingPOST(gitHubSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveGithubPersonalToken
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public saveGithubPersonalTokenUsingPOST(token: string, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).saveGithubPersonalTokenUsingPOST(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveGlobalSetting
     * @param {GlobalSetting} [globalSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public saveGlobalSettingUsingPOST(globalSetting?: GlobalSetting, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).saveGlobalSettingUsingPOST(globalSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveTwitterUserSettings
     * @param {Array<TwitterUserSetting>} [twitterUserSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public saveTwitterUserSettingsUsingPOST(twitterUserSetting?: Array<TwitterUserSetting>, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).saveTwitterUserSettingsUsingPOST(twitterUserSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateFeedsSetting
     * @param {FeedsSetting} [feedsSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public updateFeedsSettingUsingPOST(feedsSetting?: FeedsSetting, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).updateFeedsSettingUsingPOST(feedsSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateLoginUser
     * @param {string} [name] 
     * @param {LoginRequest} [loginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingControllerApi
     */
    public updateLoginUserUsingPOST(name?: string, loginRequest?: LoginRequest, options?: AxiosRequestConfig) {
        return SettingControllerApiFp(this.configuration).updateLoginUserUsingPOST(name, loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TweetControllerApi - axios parameter creator
 * @export
 */
export const TweetControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary saveTweets
         * @param {InterceptTweets} [interceptTweets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTweetsUsingPOST: async (interceptTweets?: InterceptTweets, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tweet/saveTweets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interceptTweets, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trackRead
         * @param {TweetId} [tweetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackReadUsingPOST: async (tweetId?: TweetId, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tweet/trackRead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tweetId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TweetControllerApi - functional programming interface
 * @export
 */
export const TweetControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TweetControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary saveTweets
         * @param {InterceptTweets} [interceptTweets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTweetsUsingPOST(interceptTweets?: InterceptTweets, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResultOfint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTweetsUsingPOST(interceptTweets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trackRead
         * @param {TweetId} [tweetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trackReadUsingPOST(tweetId?: TweetId, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TweetTrack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trackReadUsingPOST(tweetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TweetControllerApi - factory interface
 * @export
 */
export const TweetControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TweetControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary saveTweets
         * @param {InterceptTweets} [interceptTweets] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTweetsUsingPOST(interceptTweets?: InterceptTweets, options?: any): AxiosPromise<ApiResultOfint> {
            return localVarFp.saveTweetsUsingPOST(interceptTweets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trackRead
         * @param {TweetId} [tweetId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trackReadUsingPOST(tweetId?: TweetId, options?: any): AxiosPromise<TweetTrack> {
            return localVarFp.trackReadUsingPOST(tweetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TweetControllerApi - object-oriented interface
 * @export
 * @class TweetControllerApi
 * @extends {BaseAPI}
 */
export class TweetControllerApi extends BaseAPI {
    /**
     * 
     * @summary saveTweets
     * @param {InterceptTweets} [interceptTweets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TweetControllerApi
     */
    public saveTweetsUsingPOST(interceptTweets?: InterceptTweets, options?: AxiosRequestConfig) {
        return TweetControllerApiFp(this.configuration).saveTweetsUsingPOST(interceptTweets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trackRead
     * @param {TweetId} [tweetId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TweetControllerApi
     */
    public trackReadUsingPOST(tweetId?: TweetId, options?: AxiosRequestConfig) {
        return TweetControllerApiFp(this.configuration).trackReadUsingPOST(tweetId, options).then((request) => request(this.axios, this.basePath));
    }
}


